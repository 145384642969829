<template lang="pug">
  div
    div.Music
      p.center-loader(v-if="isBusy")
        b-spinner.align-middle.mx-2
        strong Loading...
      div.content-to-fade.enforce-max-width(v-bind:class="ready")
        b-row
          b-col(cols="12", lg="10", offset-lg="1", xl="8", offset-xl="2")
            b-row.mt-5.pb-5.album-row(v-for="album in albumList", :key="album.id", no-gutters)
              b-col.fix-gutters.album-info(cols="12", md="4")
                b-img.mb-2(:src="album.artworkurl", fluid, center, :alt="album.name")
                b-button.d-block.mt-2(v-if="album.appleurl", :href="album.appleurl", target="_blank", size="sm", variant="danger") Buy on Apple/iTunes
                b-button.d-block.mt-2(v-if="album.bandcampurl", :href="album.bandcampurl", target="_blank", size="sm", variant="info") Buy on Bandcamp
                b-button.d-block.mt-2(v-if="album.spotifyurl", :href="album.spotifyurl", target="_blank", size="sm", variant="success") Listen on Spotify
                p.mt-1.small.text-faded {{ album.copyright }}
              b-col.text-left.album-tracks(cols="12", md="8")
                ul.mb-2.tracklist(v-for="track in album.tracks", :key="track.id", v-on:click="play(track.id)", v-bind:class="{ playing: currentTrack===track.id }")
                  li.pointer
                    span.pr-1.text-faded.text-center.pl-1 {{ track.tracknumber }}.
                    span.pr-1.text-secondary-custom.headings
                      |{{ track.name }}
                      b-icon-soundwave.pl-2.playing(v-if="currentTrack===track.id")
                    span.pr-1.text-right
                      span.duration {{ track.duration }}
                      audio(:src="track.preview", :id="track.id", @ended="endPlay()")
                      b-button.ml-3.mr-2.preview-button(size="sm", variant="info")
                        span
                          b-icon-pause-fill.pr-1(v-if="currentTrack===track.id")
                          b-icon-play-fill.pr-1(v-else)
                        span Preview
        //- p {{ appleMusicRaw }}
</template>

<script>
export default {
  name: "Music",
  data() {
    return {
      fields: ["tracknumber", "name", "duration"],
      isBusy: true,
      ready: "notready",
      error: false,
      albumList: [],
      bandList: [],
      appleMusicRaw: [],
      audio: new Audio(),
      currentTrack: ""
    };
  },
  methods: {
    endPlay() {
      this.currentTrack = "";
    },
    play(handle) {
      if (this.currentTrack === handle) {
        if (this.audio.paused === false) {
          this.audio.pause();
          this.currentTrack = "";
        } else {
          this.audio.play();
        }
      } else {
        if (this.audio.paused === false) {
          this.audio.pause();
          this.audio = document.getElementById(handle);
          this.audio.play();
          this.currentTrack = handle;
        } else {
          this.audio = document.getElementById(handle);
          this.audio.play();
          this.currentTrack = handle;
        }
      }
    }
  },
  created() {
    fetch(process.env.VUE_APP_ALBUMSHEET, {
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => {
        var albumList = [];
        var albumSheetList = [];
        var appleMusicAlbums = "";

        data.values.forEach(function(value) {
          var entry = {
            platform: value[0],
            id: value[1],
            appleurl: value[3],
            spotifyurl: value[4],
            bandcampurl: value[5]
          };

          if (entry.platform === "Apple Music") {
            if (appleMusicAlbums !== "") {
              appleMusicAlbums = appleMusicAlbums + ",";
            }
            appleMusicAlbums = appleMusicAlbums + entry.id;
          }

          albumSheetList.push(entry);
        });

        this.$axios
          .get(
            "https://api.music.apple.com/v1/catalog/us/albums?ids=" +
              appleMusicAlbums,
            {
              headers: {
                Authorization: "Bearer " + process.env.VUE_APP_APPLEMUSICTOKEN
                //   Authorization:
                //     "Bearer " +
                //     "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjlUNFMzQ1lQSkEifQ.eyJpc3MiOiI1VDlGRE4zWUFZIiwiaWF0IjoxNjA3MjA5ODAxLCJleHAiOjE2MjI5MzEwMDF9.MYQoz_vfFhQq1v1ShsHrgOn_mROEjhaFsK8MGjn9KJatn-zPfp5P0xIqsGydx6P1U_LgjGwm8EyNofDimsTXiQ"
              }
            }
          )
          .then(response => {
            // handle success
            this.appleMusicRaw = response.data.data;
            response.data.data.forEach(function(value, index) {
              var trackList = [];

              var entry = {
                id: value.id,
                name: value.attributes.name,
                artist: value.attributes.artistName,
                artworkbg: value.attributes.artwork.bgColor,
                artworkurl: value.attributes.artwork.url
                  .replace("{w}", value.attributes.artwork.width)
                  .replace("{h}", value.attributes.artwork.height),
                copyright: value.attributes.copyright,
                genres: value.attributes.genreNames,
                releasedate: value.attributes.releaseDate,
                trackcount: value.attributes.trackCount,
                url: value.attributes.url,
                appleurl: albumSheetList[index+1].appleurl,
                spotifyurl: albumSheetList[index+1].spotifyurl,
                bandcampurl: albumSheetList[index+1].bandcampurl,
                tracks: value.relationships.tracks
              };

              for (var i = 0; i < entry.tracks.data.length; i++) {
                var track = {
                  id: entry.tracks.data[i].id,
                  tracknumber: entry.tracks.data[i].attributes.trackNumber,
                  name: entry.tracks.data[i].attributes.name,
                  composer: entry.tracks.data[i].attributes.composerName,
                  duration: entry.tracks.data[i].attributes.durationInMillis,
                  genres: entry.tracks.data[i].attributes.genreNames,
                  preview: entry.tracks.data[i].attributes.previews[0].url,
                  url: entry.tracks.data[i].attributes.url
                };
                var minutes = new Date(
                  1000 * Math.round(track.duration / 1000)
                ).getUTCMinutes();
                var seconds =
                  "000000000" +
                  new Date(
                    1000 * Math.round(track.duration / 1000)
                  ).getUTCSeconds();
                seconds = seconds.substr(seconds.length - 2);
                track.duration = minutes + ":" + seconds;
                trackList.push(track);
              }

              entry.tracks = trackList;

              // Push entry into the list of albums
              albumList.push(entry);
            });
            this.albumList = albumList;
          })
          .catch(error => {
            console.log(error);
            this.error = true;
          })
          .finally(() => {
            this.ready = "ready";
            this.isBusy = false;
          });
      });
  }
};
</script>

<style scoped lang="less">
.content-to-fade {
  margin-top: 80px;

  opacity: 0;

  -webkit-transition: opacity 2.8s;
  -moz-transition: opacity 2.8s;
  transition: opacity 2.8s;

  &.ready {
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .content-to-fade {
    margin-top: 40px;
  }
}

.badge {
  font-variant: normal;
  font-weight: 400;
}

button {
  font-size: 12px;
  > span > .b-icon {
    vertical-align: middle !important;
  }
}

.album-info {
  font-family: var(--sans-serif);
  > .btn {
    font-family: var(--paragraphs);
  }
}

.albumname {
  letter-spacing: 0;
}

ul.tracklist {
  display: table;
  padding-left: 0;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  > li {
    display: flex !important;
    flex-wrap: nowrap !important;
    flex-flow: row;
    list-style-type: none;
    justify-content: center;
    align-items: center;
    > span {
      transition: 0.5s all ease;
      color: var(--primary-color);
      position: relative;
      flex: auto;
      padding: 0.55rem 0px;
      background: var(--card-color);
      line-height: 34px;
      &:nth-child(1) {
        flex: initial;
        min-width: 8%;
      }
      &:nth-child(2) {
        flex: auto;
      }
      &:nth-child(3) {
        flex: initial;
      }
      .playing {
        font-size: 32px;
        color: var(--secondary-color);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      button {
        white-space: nowrap !important;
      }
    }
    &:hover > span {
      background: var(--bg-color);
      color: var(--secondary-color) !important;
    }
  }
}
</style>
